.hora {
  font-family: "Arial Black", Arial, Helvetica, sans-serif;
  font-size: 3em;

  color: #fbb034;
  color: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);

  display: flex;
  align-items: baseline;
}

.hora::after {
  content: "h";
  color: orange;
  font-size: 0.5em;
  text-align: center;
  margin-top: 3px;
  font-weight: 100;
  font-style: italic;
}

.titulo {
  color: #ffffff;
  font-weight: 400;
}

.titulo::before {
  content: "“";
  color: #fbb034;
  font-size: 1.5em;
}

.titulo::after {
  content: "”";
  color: #fbb034;
  font-size: 1.5em;
}

.texto {
  color: #fbb034;
  margin: 5px 0 !important;
}

.obs {
  color: #e63015;
  font-size: 0.8em;
  font-weight: bold;
}

.body {
  background-color: #000000;
}

@media (max-width: 40rem) {
  .logo {
    font-size: 1.1em;
  }

  .menu {
    gap: 1px;
  }
}
