@import url(https://fonts.googleapis.com/css2?family=Spectral:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap);
* {
  box-sizing: border-box;
}

body {
  padding-top: 4rem;
  margin: 0px;
  color: #333;
  --type-first: Helvetica, Arial, sans-serif;
  --type-second: "Spectral", Georgia;
  font-family: var(--type-first);
}

h1,
h2,
h3,
h4,
p {
  margin: 0px;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

img {
  display: block;
  max-width: 100%;
}

button,
input {
  display: block;
  font-size: 1rem;
  font-family: var(--type-first);
  color: #333;
}

a {
  text-decoration: none;
  color: #fff;
}

.itema a {
  text-decoration: none;
  color: #ffeb98 !important;
}

a:link .article-title {
  text-decoration: none;
  background-color: #000;
  color: #fff;
}

a:hover .article-title {
  text-decoration: none;
  background-color: rgba(63, 69, 75, 1);
  color: #ffc107;
  transition: color 0.5s, background-color 0.5s;
}

.tituloaproveite {
  font-family: "Homemade Apple", cursive;
  color: orange;
  font-size: 1em;
  text-align: center;
  margin-top: 20px !important;
  font-weight: 400;
  padding: 0 !important;
  display: block;
  opacity: 0;
  left: calc(50% - 400px);
  position: relative;
}

.tituloaproveite span {
  font-weight: bold;
}

@-webkit-keyframes dots {
  from {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(30px, 0, 0);
            transform: translate3d(30px, 0, 0);
  }
}

@keyframes dots {
  from {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  to {
    -webkit-transform: translate3d(30px, 0, 0);
            transform: translate3d(30px, 0, 0);
  }
}

.buttonz {
  margin: 60px auto;
  display: block;
  position: relative;
  width: 150px;
  padding: 70px 10px;
  color: white;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 24px;
  text-shadow: 1px 1px 1px hsl(0deg 0% 0% / 50%);
  text-decoration: none;
  border: 5px solid #aefbae;
  background-image: -moz-linear-gradient(top, #5be93a, #278312);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #5be93a),
    color-stop(1, #278312)
  );
  border-radius: 25px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  -webkit-transform: rotate(720deg) rotateY(0);
  -webkit-transition: all 0.5s;
  -webkit-transition-timing-function: cubic-bezier(1, 0.8, 0.5, 1);
  -webkit-transition-delay: 0.5s;
  -moz-transform: rotate(720deg);
  -moz-transition: all 0.5s;
  -moz-transition-timing-function: cubic-bezier(1, 0.8, 0.5, 1);
  -moz-transition-delay: 0.5s;
  -o-transform: rotate(720deg);
  -o-transition: all 0.5s;
  -o-transition-timing-function: cubic-bezier(1, 0.8, 0.5, 1);
  -o-transition-delay: 0.5s;
  z-index: 1;
  -webkit-box-shadow: inset 0 0 20px rgb(0 0 0 / 50%);
}

.buttonz:before {
  content: "";
  position: absolute;
  top: -30px;
  left: -30px;
  bottom: -30px;
  right: -30px;
  border-radius: 35px;
  z-index: -2;
  -webkit-transition: all 0.2s;
  -webkit-transform: rotate(0deg) rotateY(0);
  -webkit-transition-delay: 0;
  -moz-transition: all 0.2s;
  -moz-transform: rotate(0deg) rotateY(0);
  -moz-transition-delay: 0;
  -o-transition: all 0.2s;
  -o-transform: rotate(0deg) rotateY(0);
  -o-transition-delay: 0;
  box-shadow: inset 0 0 100px rgb(0 0 0 / 10%);
}

.buttonz:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.jello-horizontal {
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-10 22:11:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.shake-top {
  -webkit-animation: shake-top 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-top 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-11 20:36:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-top
 * ----------------------------------------
 */
@-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}

.container {
  max-width: 50rem;
  padding: 0 1rem;
  margin: 0 auto;
}

.mainContainer {
  margin-top: 2rem;
}

.title {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 3rem;
  margin: 1rem 0;
  position: relative;
  z-index: 1;
}

.title::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: #39c643;
  position: absolute;
  bottom: 5px;
  left: -5px;
  border-radius: 0.2rem;
  z-index: -1;
}

.animeLeft {
  opacity: 0;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  -webkit-animation: animeLeft 0.3s forwards;
          animation: animeLeft 0.3s forwards;
}

@-webkit-keyframes animeLeft {
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes animeLeft {
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}

.App {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh + 10rem);
}

.AppBody {
  flex: 1 1;
  padding: 4em 0 0 0;
}

.VictoryContainer {
  height: auto !important;
  height: initial !important;
}

#relogio {
  color: orange;
  font-weight: 600;
  margin-right: 5px;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: revert !important;
}

.atext-tiny {
  font-size: 0.7em;
}

.atext-small {
  font-size: 0.85em;
}

.atext-big {
  font-size: 1.4em;
}

.atext-huge {
  font-size: 1.8em;
}

textarea {
  resize: none !important;
  padding: 1em 1em !important;
}

a {
  color: #fff;
  text-decoration: none !important;
  border-bottom: none !important;
}

a:hover {
  color: #eee;
}

/* Flex styles -------------------------------------------------*/
header {
  display: flex;
  align-items: center;
}

@media (min-width: 700px) {
  .leading {
    display: flex;
    align-items: center;
  }
}

@font-face {
  font-family: "MyWebFont";
  src: url("https://entradafranca.com/webfonts/ariblk.ttf") format("truetype");
}

@font-face {
  font-family: "MyFont";
  src: local("MyFont"), url(/webfonts/ariblk1.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

#logo {
  font-family: "MyFont", "MyWebFont", "Arial Black", "Arial Bold", Gadget,
    sans-serif;
  font-weight: bold;
  border: 2px solid #eee !important;
  background-color: #36383c;
  padding: 7px;
}

/* Grid styles -------------------------------------------------*/
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1px;
  background-color: #dbd9dc;
}

/* Generic styles ----------------------------------------------*/
body {
  font-family: "Quicksand", sans-serif;
  background-color: #333;
}

header {
  padding: 10px;
  font-size: 1em;
  color: white;
  background-color: #333;
}

.logo {
  height: 50px;
  margin-right: 20px;
}

.leading {
  height: 240px;
  padding: 30px;
  color: white;
  background: url("https://cdn.glitch.com/a5121e34-96b3-4a70-8227-040c51e64fae%2Fcat.jpg?1509635989509")
    center #333 no-repeat;
  background-size: cover;
  color: white;
  text-shadow: 0px 0px 5px #000;
}

.leading-bigtext {
  margin-right: 60px;
  font-weight: bold;
  font-size: 24vw;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-9 17:13:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@media (min-width: 1008px) {
  .cards {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }

  .item2x2 {
    grid-column: span 2;
    grid-row: span 2;
  }

  .item2x1 {
    grid-column: span 2;
  }

  .item1x2 {
    grid-row: span 2;
  }
}

@media (min-width: 641px) and (max-width: 1007px) {
  .leading-bigtext {
    font-size: 140px;
  }

  .cards {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }

  .item2x2 {
    grid-column: span 2;
    grid-row: span 2;
  }

  .item2x1 {
    display: flex;
  }

  .item1x2 {
    display: flex;
  }

  .tituloaproveite {
    display: none;
  }
}

@media (min-width: 497px) and (max-width: 640px) {
  .cards {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }

  .item2x1 {
    display: flex;
  }

  .item1x2 {
    display: flex;
  }

  .tituloaproveite {
    display: none;
  }
}

@media (max-width: 496px) {
  .tituloaproveite {
    display: none;
  }

  .cards {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.leading-text {
  max-width: 900px;
  font-size: 1.2em;
  line-height: 1.4em;
}

.cards {
  max-width: 100%;
  margin: 0 auto;
}

.card {
  background-color: #6d757d;
}

.principal-img {
  display: block;
  height: 200px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  object-fit: cover;
}

article {
  position: relative;
  display: flex !important;
  box-sizing: border-box;
}

.article-img {
  display: block;
  height: auto;
  width: 100%;
  object-fit: cover;
}

.article-title {
  position: absolute;
  font-size: 1em;
  box-sizing: border-box;
  background-color: rgba(63, 69, 75, 0.5);
  left: calc(50% - 5rem);
  font-weight: bold;
  top: calc(50% - 3em);
  border-radius: 5px;
  text-align: center;
  width: 10rem;
  padding: 20px;
  text-transform: uppercase;
}

/* Panel */

.panel {
  padding: 4em 4em 2em 4em;
  -webkit-transform: translateY(-100vh);
  transform: translateY(-100vh);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-overflow-scrolling: touch;
  background: rgba(36, 38, 41, 0.975);
  top: 3.4em;
  left: 0;
  max-height: calc(90vh - 4em);
  overflow-y: auto;
  position: fixed;
  width: 100%;
  z-index: 1001;
}

.panel.active {
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}

.panel > .inner {
  margin: 0 auto;
  max-width: 100%;
  width: 75em;
}

.panel > .inner.split {
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
}

.panel > .inner.split > div {
  margin-left: 4em;
  width: 50%;
}

.panel > .inner.split > :first-child {
  margin-left: 0;
}

.panel > .closer {
  transition: opacity 0.2s ease-in-out;
  background-image: url("/images/close.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 3em;
  cursor: pointer;
  height: 5em;
  opacity: 0.25;
  position: absolute;
  right: 0;
  top: 0;
  width: 5em;
  z-index: 2;
}

.panel > .closer:hover {
  opacity: 1;
}

@media screen and (max-width: 1280px) {
  .panel {
    padding: 3em 3em 1em 3em;
  }

  .panel > .inner.split > div {
    margin-left: 3em;
  }

  .panel > .closer {
    background-size: 2.5em;
    background-position: 75% 25%;
  }
}

@media screen and (max-width: 980px) {
  .panel > .inner.split {
    -moz-flex-direction: column;
    flex-direction: column;
  }

  .panel > .inner.split > div {
    margin-left: 0;
    width: 100%;
  }
}

@media screen and (max-width: 736px) {
  .panel {
    -webkit-transform: translateY(-100vh);
    transform: translateY(-100vh);
    padding: 4em 2em 2em 2em;
    bottom: auto;
    top: calc(4em - 1px);
  }

  .panel.active {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

#header {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: -webkit-transform 1s ease;
  transition: transform 1s ease;
  transition: transform 1s ease, -webkit-transform 1s ease;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  background: #1f2224;
  height: 4em;
  padding: 0 1.5em;
  top: 0;
  left: 0;
  line-height: 4em;
  position: fixed;
  user-select: none;
  width: 100%;
  z-index: 1002;
  box-shadow: 1px 1px 30px 1px #dbd9dc;
}

#header h1 {
  color: #fff;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1em;
  line-height: 1;
  margin: 0;
  vertical-align: middle;
}

#header h1 a {
  border: 0;
  color: inherit;
}

#header h1 a:hover {
  color: inherit !important;
}

#header nav {
  position: absolute;
  right: 0;
  top: 0;
}

#header nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#header nav > ul > li {
  display: inline-block;
  padding: 0;
}

#header nav > ul > li a {
  transition: background-color 0.5s ease;
  border: 0;
  color: #ffffff;
  display: inline-block;
  letter-spacing: 0.1em;
  padding: 0 1.65em;
}

#header nav > ul > li a.icon:before {
  color: #dbd9dc;
  float: right;
  margin-left: 0.75em;
}

#header nav > ul > li a:hover {
  color: #ffffff !important;
}

#header nav > ul > li a.active {
  background-color: #242629;
}

/* Footer */

footer h3 {
  color: #fff;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1em;
  line-height: 1;
  margin: 0;
  vertical-align: middle;
}

footer .copyright {
  color: #505051;
  font-size: 0.9em;
}

#footer .copyright a {
  color: inherit;
}

/* Basic */

@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: #242629;
}

body.is-preload *,
body.is-preload *:before,
body.is-preload *:after {
  -webkit-animation: none !important;
  animation: none !important;
  transition: none !important;
}

body.is-resizing *,
body.is-resizing *:before,
body.is-resizing *:after {
  -webkit-animation: none !important;
  animation: none !important;
  transition: none !important;
}

/* Type */

body,
input,
select,
textarea {
  color: #a0a0a1;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 15pt;
  font-weight: 300;
  letter-spacing: 0.025em;
  line-height: 1.65;
}

@media screen and (max-width: 1680px) {
  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }
}

a {
  transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
  border-bottom: dotted 1px;
  color: #e5e7eb;
  text-decoration: none;
}

a:hover {
  border-bottom-color: transparent;
  color: #e5e7eb !important;
}

strong,
b {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 0.1em;
  line-height: 1.5;
  margin: 0 0 1em 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.25em;
}

h3 {
  font-size: 1.1em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.9em;
}

h6 {
  font-size: 0.7em;
}

@media screen and (max-width: 736px) {
  h2 {
    font-size: 1em;
  }

  h3 {
    font-size: 0.9em;
  }

  h4 {
    font-size: 0.8em;
  }

  h5 {
    font-size: 0.7em;
  }

  h6 {
    font-size: 0.7em;
  }
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

blockquote {
  border-left: 4px #36383c;
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
  background: #34363b;
  border: solid 1px #36383c;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1em 1.5em;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px #36383c;
  margin: 2em 0;
}

hr.major {
  margin: 3em 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -webkit-appearance: none;
  appearance: none;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #ffffff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  font-weight: 300;
  letter-spacing: 0.1em;
  line-height: 3.05556em;
  padding: 0 2.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
  color: #e5e7eb !important;
}

input[type="submit"]:hover:active,
input[type="reset"]:hover:active,
input[type="button"]:hover:active,
button:hover:active,
.button:hover:active {
  background-color: rgba(52, 165, 142, 0.15);
  color: #e5e7eb !important;
}

input[type="submit"].icon,
input[type="reset"].icon,
input[type="button"].icon,
button.icon,
.button.icon {
  padding-left: 1.35em;
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
  margin-right: 0.5em;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
  font-size: 0.8em;
}

input[type="submit"].large,
input[type="reset"].large,
input[type="button"].large,
button.large,
.button.large {
  font-size: 1.35em;
}

input[type="submit"].primary,
input[type="reset"].primary,
input[type="button"].primary,
button.primary,
.button.primary {
  background-color: #36383c;
  box-shadow: none;
}

.special {
  background-color: #36383c;
}

input[type="submit"].primary:hover,
input[type="reset"].primary:hover,
input[type="button"].primary:hover,
button.primary:hover,
.button.primary:hover {
  background-color: #47c5ab;
  color: #ffffff !important;
}

input[type="submit"].primary:hover:active,
input[type="reset"].primary:hover:active,
input[type="button"].primary:hover:active,
button.primary:hover:active,
.button.primary:hover:active {
  background-color: #287e6d;
}

input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  pointer-events: none;
  opacity: 0.35;
}

/* Form */

form {
  margin: 0 0 2em 0;
  width: 100%;
  box-sizing: border-box;
}

form > :last-child {
  margin-bottom: 0;
}

form > .fields {
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  width: calc(100% + 2em);
  flex-wrap: wrap;
  margin: -1.5em 0 2em -1.5em;
}

form > .fields > .field {
  -moz-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
  padding: 1.5em 0 0 1.5em;
  width: calc(100% - 1.5em);
}

form > .fields > .field.half {
  width: calc(50% - 0.75em);
}

form > .fields > .field.third {
  width: calc(100% / 3 - 0.5em);
}

form > .fields > .field.quarter {
  width: calc(25% - 0.375em);
}

@media screen and (max-width: 736px) {
  form > .fields {
    width: 100%;
    margin: 0 0 2em 0;
  }

  form > .fields > .field {
    padding: 1.5em 0 0 1.5em;
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.half {
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.third {
    width: calc(100% - 1.5em);
  }

  form > .fields > .field.quarter {
    width: calc(100% - 1.5em);
  }
}

label {
  color: #ffffff;
  display: block;
  font-size: 0.9em;
  font-weight: 300;
  margin: 0 0 1em 0;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  background: #34363b;
  border: 0;
  border-radius: 0;
  color: #a0a0a1;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
input[type="tel"]:invalid,
input[type="search"]:invalid,
input[type="url"]:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="search"]:focus,
input[type="url"]:focus,
select:focus,
textarea:focus {
  box-shadow: inset 0 0 0 2px #e5e7eb;
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%2336383c' /%3E%3C/svg%3E");
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  height: 2.75em;
  padding-right: 2.75em;
  text-overflow: ellipsis;
}

select option {
  color: #ffffff;
  background: #242629;
}

select:focus::-ms-value {
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="url"],
select {
  height: 2.75em;
}

textarea {
  padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  text-decoration: none;
  color: #a0a0a1;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 300;
  padding-left: 2.4em;
  padding-right: 0.75em;
  position: relative;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  background: #34363b;
  content: "";
  display: inline-block;
  font-size: 0.8em;
  height: 2.0625em;
  left: 0;
  line-height: 2.0625em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2.0625em;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  background: #e5e7eb;
  border-color: #e5e7eb;
  color: #ffffff;
  content: "\f00c";
}

input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
  box-shadow: 0 0 0 2px #e5e7eb;
}

input[type="radio"] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  color: #707071 !important;
  opacity: 1;
}

:-moz-placeholder {
  color: #707071 !important;
  opacity: 1;
}

::-moz-placeholder {
  color: #707071 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #707071 !important;
  opacity: 1;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-weight: 400;
}

.icon > .label {
  display: none;
}

.icon:before {
  line-height: inherit;
}

.icon.solid:before {
  font-weight: 900;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}

ul li {
  padding-left: 0.5em;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px #36383c;
  padding: 0.5em 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

dl {
  margin: 0 0 2em 0;
}

dl dt {
  display: block;
  font-weight: 300;
  margin: 0 0 1em 0;
}

dl dd {
  margin-left: 2em;
}

/* Actions */

ul.actions {
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  cursor: default;
  list-style: none;
  margin-left: -1em;
  padding-left: 0;
}

ul.actions li {
  padding: 0 0 0 1em;
  vertical-align: middle;
}

ul.actions.special {
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: 100%;
  margin-left: 0;
}

ul.actions.special li:first-child {
  padding-left: 0;
}

ul.actions.stacked {
  -moz-flex-direction: column;
  flex-direction: column;
  margin-left: 0;
}

ul.actions.stacked li {
  padding: 1.3em 0 0 0;
}

ul.actions.stacked li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  width: calc(100% + 1em);
}

ul.actions.fit li {
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
  width: 100%;
}

ul.actions.fit li > * {
  width: 100%;
}

ul.actions.fit.stacked {
  width: 100%;
}

@media screen and (max-width: 480px) {
  ul.actions:not(.fixed) {
    -moz-flex-direction: column;
    flex-direction: column;
    margin-left: 0;
    width: 100% !important;
  }

  ul.actions:not(.fixed) li {
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
    padding: 1em 0 0 0;
    text-align: center;
    width: 100%;
  }

  ul.actions:not(.fixed) li > * {
    width: 100%;
  }

  ul.actions:not(.fixed) li:first-child {
    padding-top: 0;
  }

  ul.actions:not(.fixed) li input[type="submit"],
  ul.actions:not(.fixed) li input[type="reset"],
  ul.actions:not(.fixed) li input[type="button"],
  ul.actions:not(.fixed) li button,
  ul.actions:not(.fixed) li .button {
    width: 100%;
  }

  ul.actions:not(.fixed) li input[type="submit"].icon:before,
  ul.actions:not(.fixed) li input[type="reset"].icon:before,
  ul.actions:not(.fixed) li input[type="button"].icon:before,
  ul.actions:not(.fixed) li button.icon:before,
  ul.actions:not(.fixed) li .button.icon:before {
    margin-left: -0.5rem;
  }
}

/* Icons */

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

ul.icons li {
  display: inline-block;
  padding: 0 1em 0 0;
}

ul.icons li:last-child {
  padding-right: 0;
}

ul.icons li .icon {
  color: #505051;
}

ul.icons li .icon:before {
  font-size: 1.5em;
}


@media (max-width: 576px) {
  ul.icons {
    flex-direction: column;
  }  
}


.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.OverlayPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
}

.Modal {
  position: absolute;
  top: 60px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  overflow: hidden;
  background-color: papayawhip;
}

.ModalCat {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: 40px;
  overflow: hidden;
  width: 100%;
  min-height: calc(100vh - 5em);
  bottom: auto;
  margin-top: 40px;
  margin-right: -50%;
  border: 4px solid #212529;
  border-radius: 5px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: rgb(65 71 74);
  position: relative;
}

@media (max-width: 992px) {
  .ModalCat {
    margin-top: 30px;
    min-height: calc(100vh - 4em);
  }
}

.bounce-in-top {
  -webkit-animation: bounce-in-top 1.1s both;
  animation: bounce-in-top 1.1s both;
}

/* ----------------------------------------------
        * Generated by Animista on 2021-6-26 20:51:29
        * Licensed under FreeBSD License.
        * See http://animista.net/license for more info. 
        * w: http://animista.net, t: @cssanimista
        * ---------------------------------------------- */

/**
        * ----------------------------------------
        * animation bounce-in-top
        * ----------------------------------------
        */
@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
    transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
    transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
    transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.bounce-out-bottom {
  -webkit-animation: bounce-out-bottom 1.5s both;
  animation: bounce-out-bottom 1.5s both;
}

/* ----------------------------------------------
         * Generated by Animista on 2021-7-3 17:52:17
         * Licensed under FreeBSD License.
         * See http://animista.net/license for more info. 
         * w: http://animista.net, t: @cssanimista
         * ---------------------------------------------- */

/**
         * ----------------------------------------
         * animation bounce-out-bottom
         * ----------------------------------------
         */
@-webkit-keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  30% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px);
    transform: translateY(800px);
    opacity: 0;
  }
}
@keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  30% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px);
    transform: translateY(800px);
    opacity: 0;
  }
}

.fade-out-bottom {
  -webkit-animation: fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: fade-out-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-26 19:37:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
}

.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-26 19:40:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.close {
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
}

.close span {
  outline: none;
  color: #ff0000;
  font-size: 3em;
}

/* width */
-webkit-scrollbar {
  width: 5px;
}

/* Track */
-webkit-scrollbar-track {
  background: #222222;
}

/* Handle */
-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (min-width: 992px) {
  #header {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-size: 1.3em;
    transition: font-size 0.3s, padding-top 0.3s, padding-bottom 0.3s;
  }
  #header .navbar-brand {
    font-size: 1.75em;
    transition: font-size 0.3s;
  }
  #header .navbar-nav {
    margin-top: 0;
  }
  #header .navbar-nav > li.nav-item > a.nav-link.active {
    color: #fff;
    background: #efcb3a;
  }
  #header .navbar-nav > li.nav-item > a.nav-link.active:active,
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active:focus,
  #mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover {
    color: #fff;
    background: #efcb3a;
  }
  #header.navbar-shrink {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1em;
  }
  #header.navbar-shrink .tituloaproveite {
    transition: opacity 1s ease-in-out;
    opacity: 1;
  }
  #header.navbar-shrink .navbar-brand {
    font-size: 1.5em;
  }
}

.scroll-to-top-x {
  position: fixed;
  z-index: 1042;
  right: 1rem;
  bottom: 1rem;
}

.scroll-to-top-x button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background-color: rgba(44, 62, 80, 0.31);
  line-height: 3.1rem;
  padding: 15px;
  margin: -5px;
  border: none;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.css-button-3d--sand {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 5px;
  border: none;
  background: #ced4da;
  box-shadow: 0 5px #adb5bd;
}
.css-button-3d--sand:hover {
  box-shadow: 0 3px #adb5bd;
  top: 1px;
}
.css-button-3d--sand:active {
  box-shadow: 0 0 #adb5bd;
  top: 5px;
}

/*
 * CKEditor 5 (v34.1.0) content styles.
 * Generated on Mon, 20 Jun 2022 11:42:49 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
  --ck-color-base-active: hsl(208, 88%, 52%);
  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-color-table-caption-background: hsl(0, 0%, 97%);
  --ck-color-table-caption-text: hsl(0, 0%, 20%);
  --ck-color-table-column-resizer-hover: var(--ck-color-base-active);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
  --ck-table-column-resizer-position-offset: calc(
    var(--ck-table-column-resizer-width) * -0.5 - 0.5px
  );
  --ck-table-column-resizer-width: 7px;
  --ck-todo-list-checkmark-size: 16px;
  --ck-z-default: 1;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: hsl(60, 97%, 73%);
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: hsl(120, 93%, 68%);
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: hsl(345, 96%, 73%);
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: hsl(201, 97%, 72%);
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: hsl(0, 85%, 49%);
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: hsl(112, 100%, 27%);
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-image-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
  display: flex;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
  max-width: calc(100% - 1.5em);
  max-width: calc(100% - var(--ck-image-style-spacing));
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
  clear: none;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: 1.5em;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: 1.5em;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
  margin-top: 0;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
  margin-top: calc(1.5em / 2);
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: calc(1.5em / 2);
  margin-bottom: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
  margin-right: calc(1.5em / 2);
  margin-right: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
  margin-left: calc(1.5em / 2);
  margin-left: var(--ck-inline-image-style-spacing);
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
  font-style: italic;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: 16px;
  width: var(--ck-todo-list-checkmark-size);
  height: 16px;
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(16px / 3);
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(16px / 5.3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(16px / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(16px / 2.6);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(16px / 8)
    calc(16px / 8) 0;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: "";
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
  overflow: hidden;
  table-layout: fixed;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
  position: relative;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .table-column-resizer {
  position: absolute;
  top: -999999px;
  bottom: -999999px;
  right: calc(
    7px * -0.5 - 0.5px
  );
  right: var(--ck-table-column-resizer-position-offset);
  width: 7px;
  width: var(--ck-table-column-resizer-width);
  cursor: col-resize;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 1;
  z-index: var(--ck-z-default);
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table[draggable] .table-column-resizer {
  display: none;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .table-column-resizer:hover,
.ck-content .table .table-column-resizer__active {
  background-color: hsl(208, 88%, 52%);
  background-color: var(--ck-color-table-column-resizer-hover);
  opacity: 0.25;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content[dir="rtl"] .table .table-column-resizer {
  left: calc(
    7px * -0.5 - 0.5px
  );
  left: var(--ck-table-column-resizer-position-offset);
  right: unset;
}
/* ckeditor5-table/theme/tablecolumnresize.css */
.ck-content.ck-read-only .table .table-column-resizer {
  display: none;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: hsl(0, 0%, 20%);
  color: var(--ck-color-table-caption-text);
  background-color: hsl(0, 0%, 97%);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 0.9em auto;
  display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
  text-align: left;
}

/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: hsla(341, 100%, 30%, 0.1);
  background: var(--ck-color-mention-background);
  color: hsl(341, 100%, 30%);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}

.Header_logo__3qxVW {
  font-family: "Arial Black", Arial, Helvetica, sans-serif;
  font-size: 1.7em;
  display: flex;
  flex-direction: column;
  z-index: 100;
  margin-left: -10px;
}

.Header_data__1oqky {
  color : #fff; 
  font-size : 0.6em;
  position: absolute;
  top: 10px;
  padding: 0;
  margin: 0;
  height: 1px;
}

.Header_divx__18NVc {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
}

.Header_menu__csZv9 {
  display: flex !important;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}

@media (max-width: 40rem) {
  .Header_logo__3qxVW {
    font-size: 1.1em;
  }

  .Header_menu__csZv9 {
    grid-gap: 1px;
    gap: 1px;
  }
}

.Footer_footer___TGwz {
  background: #1f2224;
  padding: 2rem 1rem 0 1rem;
  height: 10rem;
  text-align: center;
  color: #fff;
}

.Footer_logo__23AO_ {
  font-family: "Arial Black", Arial, Helvetica, sans-serif;
  font-size: 1.7em;
}

.Footer_footer___TGwz p {
  margin-top: 1rem;
  font-size: 1.1em;
}

@media (max-width: 40rem) {
  .Footer_footer___TGwz p {
    font-size: 0.875em;
  }
}

.FeedModal_modal__2q3WA {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 1000;
  padding: 2rem calc(4rem + 15px) 2rem 4rem;
}

@media (max-width: 40rem) {
  .FeedModal_modal__2q3WA {
    padding: 2rem calc(2rem + 15px) 2rem 2rem;
  }
}

.Loading_wrapper__-h4Xr {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background: #1f2224;
}

.Loading_loading__1rT8R {
  margin: auto;
  width: 80px;
  height: 80px;
  display: flex;
  padding-left: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.PhotoContent_photo__2yyJf {
  margin: auto;
  height: 36rem;
  border-radius: 0.2rem;
  background: white;
  display: grid;
  grid-template-columns: 36rem 20rem;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-animation: PhotoContent_scaleUp__3fC3N 0.3s forwards;
          animation: PhotoContent_scaleUp__3fC3N 0.3s forwards;
}

.PhotoContent_single__kB5qg.PhotoContent_photo__2yyJf {
  grid-template-columns: 1fr;
  height: auto;
}

@-webkit-keyframes PhotoContent_scaleUp__3fC3N {
  to {
    opacity: 1;
    opacity: initial;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}

@keyframes PhotoContent_scaleUp__3fC3N {
  to {
    opacity: 1;
    opacity: initial;
    -webkit-transform: none;
            transform: none;
    -webkit-transform: initial;
            transform: initial;
  }
}

.PhotoContent_details__3Qp-P {
  padding: 2rem 2rem 0 2rem;
}

.PhotoContent_single__kB5qg .PhotoContent_details__3Qp-P {
  padding: 1rem 0px 0px 0px;
}

.PhotoContent_img__2Or-1 {
  grid-row: 1/4;
}

.PhotoContent_single__kB5qg .PhotoContent_img__2Or-1 {
  grid-row: 1;
  border-radius: 0.4rem;
  overflow: hidden;
}

@media (max-width: 64rem) {
  .PhotoContent_photo__2yyJf {
    height: auto;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
    grid-template-columns: minmax(20rem, 40rem);
  }
  .PhotoContent_img__2Or-1 {
    grid-row: 1;
  }
}

.PhotoContent_author__2XuL2 {
  opacity: 0.5;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PhotoContent_author__2XuL2 a:hover {
  text-decoration: underline;
}

.PhotoContent_visualizacoes__1h-Ml::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-right: 0.5rem;
  background: url(/static/media/visualizacao-black.957befc3.svg);
}

.PhotoContent_attributes__21Kns {
  display: flex;
  font-size: 1.125rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.PhotoContent_attributes__21Kns li {
  margin-right: 2rem;
}

.PhotoContent_attributes__21Kns li::before {
  content: '';
  display: inline-block;
  height: 20px;
  margin-right: 0.5rem;
  position: relative;
  top: 3px;
  width: 2px;
  background: #333;
  margin-top: 5px;
}

.PhotoCommentsForm_form__vdj9I {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: stretch;
  margin: 1rem;
}

.PhotoCommentsForm_single__3J5DT.PhotoCommentsForm_form__vdj9I {
  margin: 1rem 0;
}

.PhotoCommentsForm_textarea__3Zf7s {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-family: var(--type-first);
  resize: none;
  border: 1px solid #eee;
  padding: 0.5rem;
  border-radius: 0.2rem;
  background: #eee;
  transition: 0.2s;
}

.PhotoCommentsForm_textarea__3Zf7s:focus,
.PhotoCommentsForm_textarea__3Zf7s:hover {
  outline: none;
  border-color: #fb1;
  background: white;
  box-shadow: 0 0 0 3px #fea;
}

.PhotoCommentsForm_button__3lPa8 {
  border: none;
  cursor: pointer;
  color: #333;
  background: transparent;
  font-size: 1rem;
  padding: 0 1rem;
  overflow: hidden;
}

.PhotoCommentsForm_button__3lPa8:focus,
.PhotoCommentsForm_button__3lPa8:hover {
  outline: none;
}

.PhotoCommentsForm_button__3lPa8:focus svg path,
.PhotoCommentsForm_button__3lPa8:hover svg path {
  fill: #fea;
  stroke: #fb1;
}

.PhotoCommentsForm_button__3lPa8:focus svg g,
.PhotoCommentsForm_button__3lPa8:hover svg g {
  -webkit-animation: PhotoCommentsForm_latir__3qz-t 0.6s infinite;
          animation: PhotoCommentsForm_latir__3qz-t 0.6s infinite;
}

@-webkit-keyframes PhotoCommentsForm_latir__3qz-t {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes PhotoCommentsForm_latir__3qz-t {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.PhotoComments_comments__2ODj6 {
  overflow-y: auto;
  word-break: break-word;
  padding: 0 2rem;
}

.PhotoComments_single__3OxlH.PhotoComments_comments__2ODj6 {
  padding: 0px;
}

.PhotoComments_comments__2ODj6 li {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.PhotoDelete_delete__1K5EB {
  background: #ddd;
  padding: 0.3rem 0.6rem;
  line-height: 1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  font-family: var(--type-first);
  cursor: pointer;
  border-radius: 0.4rem;
  transition: 0.1s;
}

.PhotoDelete_delete__1K5EB:focus,
.PhotoDelete_delete__1K5EB:hover {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
}

.Image_wrapper__9Q7wW {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;  
}

.Image_img__vtaWJ {
  display: block;
  max-width: 100%;
  grid-area: 1/1;
  opacity: 0;
  transition: 0.2s;
}

.Image_skeleton__2HTIQ {
  grid-area: 1/1;
  height: 100%;
  background-image: linear-gradient(90deg, #eee 0px, #fff 50%, #eee 100%);
  background-color: #eee;
  background-size: 200%;
  -webkit-animation: Image_skeleton__2HTIQ 1.5s infinite linear;
          animation: Image_skeleton__2HTIQ 1.5s infinite linear;
}

@-webkit-keyframes Image_skeleton__2HTIQ {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}

@keyframes Image_skeleton__2HTIQ {
  from {
    background-position: 0px;
  }
  to {
    background-position: -200%;
  }
}

.FeedPhotosItem_photo__2HN97:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: span 2;
}

@media (max-width: 40rem) {
  .FeedPhotosItem_photo__2HN97:nth-child(2) {
    grid-column: initial;
    grid-row: initial;
  }
}

.FeedPhotosItem_photo__2HN97 {
  display: grid;
  border-radius: 0.2rem;
  overflow: hidden;
  cursor: pointer;
}

.FeedPhotosItem_photo__2HN97 > div {
  grid-area: 1/1;
}

.FeedPhotosItem_visualizacao__F7FOZ {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-size: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  grid-area: 1/1;
  display: none;
}

.FeedPhotosItem_visualizacao__F7FOZ::before {
  width: 16px;
  height: 10px;
  content: '';
  display: inline-block;
  margin-right: 0.25rem;
  background: url(/static/media/visualizacao.4ce15ed8.svg) no-repeat;
}

.FeedPhotosItem_photo__2HN97:hover .FeedPhotosItem_visualizacao__F7FOZ {
  display: flex;
}

.FeedPhotos_feed__1gfB5 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-items: center;
}

@media (max-width: 40rem) {
  .FeedPhotos_feed__1gfB5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.Input_wrapper__NNV2g {
  margin-bottom: 1rem;
}

.Input_input__3syx1 {
  border: 1px solid #eee;
  display: block;
  width: 100%;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 0.4rem;
  background: #eee;
  transition: 0.2s;
}

.Input_input__3syx1:focus,
.Input_input__3syx1:hover {
  outline: none;
  border-color: #39c643;
  background: white;
  box-shadow: 0 0 0 3px #fea;
}

.Input_label__2sW21 {
  display: block;
  font-size: 1rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.Input_error__1BfzW {
  color: #f31;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.Categories_hora__3F0Mq {
  font-family: "Arial Black", Arial, Helvetica, sans-serif;
  font-size: 3em;

  color: #fbb034;
  color: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%);

  display: flex;
  align-items: baseline;
}

.Categories_hora__3F0Mq::after {
  content: "h";
  color: orange;
  font-size: 0.5em;
  text-align: center;
  margin-top: 3px;
  font-weight: 100;
  font-style: italic;
}

.Categories_titulo__3x81i {
  color: #ffffff;
  font-weight: 400;
}

.Categories_titulo__3x81i::before {
  content: "“";
  color: #fbb034;
  font-size: 1.5em;
}

.Categories_titulo__3x81i::after {
  content: "”";
  color: #fbb034;
  font-size: 1.5em;
}

.Categories_texto__FNuTL {
  color: #fbb034;
  margin: 5px 0 !important;
}

.Categories_obs__2J_a6 {
  color: #e63015;
  font-size: 0.8em;
  font-weight: bold;
}

.Categories_body__asq7X {
  background-color: #000000;
}

@media (max-width: 40rem) {
  .Categories_logo__1LuOH {
    font-size: 1.1em;
  }

  .Categories_menu__1sttc {
    grid-gap: 1px;
    gap: 1px;
  }
}

.Button_button__3TH6d {
  font-size: 1rem;
  font-family: var(--type-first);
  cursor: pointer;
  border: none;
  border-radius: 0.4rem;
  background: #39c643;
  color: #FFF;
  min-width: 8rem;
  padding: 0.8rem 1.2rem;
  box-sizing: border-box;
  transition: 0.1s;
}

.Button_button__3TH6d:hover,
.Button_button__3TH6d:focus {
  outline: none;
  box-shadow: 0 0 0 3px #fea, 0 0 0 4px #39c643;
}

.Button_button__3TH6d:disabled {
  opacity: 0.5;
  cursor: wait;
}

.LoginForm_form__3QSHb {
  margin-bottom: 2rem;
}

.LoginForm_perdeu__31OoT {
  display: inline-block;
  color: #666;
  padding: 0.5rem 0;
  line-height: 1;
}

.LoginForm_perdeu__31OoT::after {
  content: '';
  height: 2px;
  width: 100%;
  background: currentColor;
  display: block;
}

.LoginForm_cadastro__2y0Vr {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.LoginForm_cadastro__2y0Vr p {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.LoginForm_subtitle__1vovp {
  font-family: var(--type-second);
  line-height: 1;
  font-size: 2rem;
}

.LoginForm_subtitle__1vovp::after {
  content: '';
  display: block;
  background: #ddd;
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.2rem;
}

.Login_login__3YXhf {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100vh;
  grid-gap: 2rem;
  gap: 2rem;
}

.Login_login__3YXhf::before {
  display: block;
  content: '';
  background: url(/static/media/login.30da19fa.webp) no-repeat center center;
  background-size: cover;
}

.Login_forms__4mtPf {
  max-width: 30rem;
  padding: 1rem;
  display: flex;
  align-items: center;
}

@media (max-width: 40rem) {
  .Login_login__3YXhf {
    grid-template-columns: 1fr;
  }
  .Login_login__3YXhf::before {
    display: none;
  }
  .Login_forms__4mtPf {
    max-width: 100%;
  }
}

.UserHeaderNav_nav__L7HKO {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

.UserHeaderNav_nav__L7HKO a,
.UserHeaderNav_nav__L7HKO button {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.UserHeaderNav_nav__L7HKO a:hover,
.UserHeaderNav_nav__L7HKO a:focus,
.UserHeaderNav_nav__L7HKO button:hover,
.UserHeaderNav_nav__L7HKO button:focus {
  background: white;
  box-shadow: 0 0 0 3px #eee;
  border-color: #333;
  outline: none;
}

.UserHeaderNav_nav__L7HKO a.active {
  background: white;
  box-shadow: 0 0 0 3px #fea;
  border-color: #fb1;
}

.UserHeaderNav_nav__L7HKO a.active svg > * {
  fill: #fb1;
}

.UserHeaderNav_mobileButton__5WP4t {
  background: #eee;
  border-radius: 0.2rem;
  height: 40px;
  width: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  transition: 0.1s;
  cursor: pointer;
}

.UserHeaderNav_mobileButton__5WP4t::after {
  content: '';
  display: block;
  width: 1.2rem;
  height: 2px;
  border-radius: 2px;
  background: currentColor;
  box-shadow: 0 6px currentColor, 0 -6px currentColor;
  transition: 0.2s;
}

.UserHeaderNav_mobileButton__5WP4t:focus,
.UserHeaderNav_mobileButton__5WP4t:hover,
.UserHeaderNav_mobileButtonActive__3zKX0 {
  outline: none;
  background: white;
  box-shadow: 0 0 0 3px #fea;
  border-color: #fb1;
  color: #fb1;
}

.UserHeaderNav_mobileButtonActive__3zKX0::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 4px;
  height: 4px;
  box-shadow: 0 8px currentColor, 0 -8px currentColor;
}

.UserHeaderNav_navMobile__xRBgZ {
  display: block;
  position: absolute;
  top: 70px;
  right: 0px;
  padding: 0 1rem;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
  opacity: 0;
  pointer-events: none;
}

.UserHeaderNav_navMobileActive__9tQXH {
  transition: 0.3s;
  -webkit-transform: none;
          transform: none;
  -webkit-transform: initial;
          transform: initial;
  opacity: 1;
  pointer-events: initial;
  z-index: 100;
}

.UserHeaderNav_navMobile__xRBgZ a,
.UserHeaderNav_navMobile__xRBgZ button {
  display: flex;
  align-items: center;
  background: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0;
  cursor: pointer;
}

.UserHeaderNav_navMobile__xRBgZ a:hover svg > *,
.UserHeaderNav_navMobile__xRBgZ button:hover svg > * {
  fill: #fb1;
}

.UserHeaderNav_navMobile__xRBgZ button {
  border-bottom: none;
}

.UserHeaderNav_navMobile__xRBgZ svg {
  margin-right: 0.5rem;
}

.UserHeader_header__2Sifa {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  position: relative;
}

.UserPhotoPost_photoPost__1ycXk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 40rem) {
  .UserPhotoPost_photoPost__1ycXk {
    grid-template-columns: 1fr;
  }
}

.UserPhotoPost_file__3w6Lh {
  margin-bottom: 1rem;
}

.UserPhotoPost_preview__2FjGo {
  border-radius: 1rem;
  background-size: cover;
  background-position: center center;
}

.UserPhotoPost_preview__2FjGo::after {
  content: '';
  display: block;
  height: 0px;
  padding-bottom: 100%;
}

.Loading_loading__1V0Q3 {
  height: 2px;
  background: black;
  overflow: hidden;
  max-width: 25%;
  margin: 2rem auto;
}

.Loading_loading__1V0Q3::after {
  content: '';
  display: block;
  width: 75%;
  height: 2px;
  background: white;
  -webkit-animation: Loading_loading__1V0Q3 0.5s linear forwards infinite;
          animation: Loading_loading__1V0Q3 0.5s linear forwards infinite;
}

@-webkit-keyframes Loading_loading__1V0Q3 {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(125%);
            transform: translateX(125%);
  }
}

@keyframes Loading_loading__1V0Q3 {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(125%);
            transform: translateX(125%);
  }
}

.Login_label__1Jvcr,
.Login_input__3K2lO,
.Login_button__12Ktx {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
}

.Login_label__1Jvcr {
  display: block;
  margin-bottom: 0.25rem;
}

.Login_input__3K2lO {
  border: 1px solid;
  border-radius: 3px;
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  display: block;
}

.Login_button__12Ktx {
  color: white;
  background: black;
  border: none;
  padding: 0.7rem 2rem;
  margin-top: 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}

.PhotosContent_list__2oafL {
  padding: 0px;
  margin: 0px;
  list-style: none;
  position: relative;
}

.PhotosContent_item__prBSG {
  display: grid;
  grid-template-columns: 50px 1fr auto auto auto auto;
  grid-template-rows: 50px;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.PhotosContent_img__3agn5 {
  max-width: 100%;
  border-radius: 4px;
}

.PhotosContent_title__2uxzB {
  font-size: 1rem;
  font-weight: normal;
  margin: 0px;
}

.PhotosContent_acessos__3i2TK {
  font-family: monospace;
  font-size: 0.875rem;
  color: #999;
}

.PhotosContent_modalitem__2UjbR {
  height: 200px;
  width: 200px;
  padding: 2em;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 200px);
  background-color: #FFF;
  z-index: 10;
  border-radius: 10px;
  transition: all 0.3s;
  box-sizing: border-box;
  border: 1px solid #39c643;
}

.PhotosContent_fechar__Riy07 {
  height: 10px;
  width: 10px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: inline-table;
  cursor: pointer;
  z-index: 15;
}

.PhotosLoadMore_button__3UnRw {
  margin: 1rem 0 0 auto;
  display: block;
  width: 32px;
  height: 32px;
  background: black;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  font-family: Roboto, sans-serif;
  border: none;
}

.Photos_formulariosearch__3qdDp {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
}

.Header_header__2-nd2 {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.Header_title___gLvr {
  font-size: 1rem;
  margin: 0px;
}

.Header_login__2iynJ {
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  display: block;
  background: white;
  width: 8px;
  height: 8px;
  border: 2px solid;
  box-sizing: border-box;
  border-radius: 50%;
}

.Header_login__2iynJ.Header_loading__2knyQ {
  background: orange;
}

.Header_login__2iynJ.Header_loaded__z-syH {
  background: greenyellow;
}

