.list {
  padding: 0px;
  margin: 0px;
  list-style: none;
  position: relative;
}

.item {
  display: grid;
  grid-template-columns: 50px 1fr auto auto auto auto;
  grid-template-rows: 50px;
  align-items: center;
  gap: 1rem;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.img {
  max-width: 100%;
  border-radius: 4px;
}

.title {
  font-size: 1rem;
  font-weight: normal;
  margin: 0px;
}

.acessos {
  font-family: monospace;
  font-size: 0.875rem;
  color: #999;
}

.modalitem {
  height: 200px;
  width: 200px;
  padding: 2em;
  position: absolute;
  left: calc(50% - 100px);
  top: calc(50% - 200px);
  background-color: #FFF;
  z-index: 10;
  border-radius: 10px;
  transition: all 0.3s;
  box-sizing: border-box;
  border: 1px solid #39c643;
}

.fechar {
  height: 10px;
  width: 10px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  display: inline-table;
  cursor: pointer;
  z-index: 15;
}
