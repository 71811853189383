.footer {
  background: #1f2224;
  padding: 2rem 1rem 0 1rem;
  height: 10rem;
  text-align: center;
  color: #fff;
}

.logo {
  font-family: "Arial Black", Arial, Helvetica, sans-serif;
  font-size: 1.7em;
}

.footer p {
  margin-top: 1rem;
  font-size: 1.1em;
}

@media (max-width: 40rem) {
  .footer p {
    font-size: 0.875em;
  }
}
